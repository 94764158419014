/* eslint-disable react/prefer-stateless-function */
import React from "react"
import StoreFinderIcon from "../images/header/storefinder.svg"
import AccountIcon from "../images/header/account.svg"
import NewsLetterIcon from "../images/header/newsletter.svg"
import OferteThumbnail from "../images/header/thumbnails/oferte.jpg"
import CardThumbnail from "../images/header/thumbnails/card.jpg"
import CopertaSortimentThumbnail from "../images/header/thumbnails/coperta-sortiment.jpg"
import CopertaTeaserThumbnail from "../images/header/thumbnails/coperta-teaser-noutati.jpg"
import CopertaMarciThumbnail from "../images/header/thumbnails/coperta-teaser-marci-proprii.jpg"
import CopertaCampaniiCardThumbnail from "../images/header/thumbnails/coperta-campanii-concursuri-card.jpg"
import CopertaCardThumbnail from "../images/header/thumbnails/coperta-cum-obtii-card-penny.jpg"
import CopertaContThumbnail from "../images/header/thumbnails/coperta-my-account.jpg"
import CopertaCampaniiThumbnail from "../images/header/thumbnails/coperta-campanii.jpg"
import CopertaReteteThumbnail from "../images/header/thumbnails/coperta-retete.jpg"
import CopertaPennyFmThumbnail from "../images/header/thumbnails/coperta-penny-fm.jpg"
import LauraThumbnail from "../images/header/thumbnails/laura_rus_1500.jpg"
import SuporteroThumbnail from "../images/header/thumbnails/suportero.jpg"
import CopertaMemoriaThumbnail from "../images/header/thumbnails/coperta-memoria-gustului.jpg"
import CopertaProduseThumbnail from "../images/header/thumbnails/coperta-produse-hb.jpg"
import CopertaReteteHBThumbnail from "../images/header/thumbnails/coperta-retete-hb.jpg"
import CopertaRaportThumbnail from "../images/header/thumbnails/coperta-raport-sust.jpg"
import CopertaCampaniiSusThumbnail from "../images/header/thumbnails/coperta-campanii-sus.jpg"

class Header extends React.Component {
  render() {
    const { page } = this.props

    return (
      <nav class="navbar navbar-expand-lg fixed-top navbar-light bg-white">
        <div class="container flex-row align-items-md-end">
          <a class="navbar-brand brand-logo mr-3" href="https://www.penny.ro/" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 396.19 400" preserveAspectRatio="xMaxYMin meet">
              <path fill-rule="evenodd" clip-rule="evenodd" fill="#cd1316" d="M28.69 31h338.52v338.52H28.69z"></path>
              <path d="M339.83 216.91a11.69 11.69 0 11-23.37 0 11.69 11.69 0 0123.37 0z" fill="#ffd400"></path>
              <path
                d="M80.64 199.23h4.39c4.94 0 7.94-3.05 8.47-6.86.46-3.29-1.83-6.86-6.48-6.86h-4.17l-2.21 13.72zm219.74-7.93l9.89-18.56h22.75l-34.34 54.35h-23.15l11.01-15.51-12.56-37.69-8.57 53.2H247.2l-11.18-25.11-4.04 25.11h-18.21l8.75-54.35h18.68l10.83 24.32 3.92-24.32h39.79l4.64 18.56zm-85.09-18.56l-8.75 54.35h-18.21l-11.18-25.11-4.04 25.11H154.9l8.75-54.35h18.68l10.83 24.32 3.92-24.32h18.21zm-123.09-.83c12.58 0 21.11 6.8 21.19 17.78l2.73-16.95h41.23l-2.21 13.75h-21.76L132.3 193H152l-2.19 13.59h-19.77l-1.12 6.75h22.17l-2.21 13.75h-41.5l4.74-29.43c-3.19 9.53-11.89 15.15-23.27 15.15h-10.4l-2.3 14.28H56.36l8.89-55.18H92.2z"
                fill-rule="evenodd" clip-rule="evenodd" fill="#fff"></path>
            </svg>
          </a>

          <div class="offcanvas-collapse navbar-collapse bg-collapse order-md-2 order-3 bg-white" id="navbarMenu">
            <div class="w-100">
              <div class="navbar-slogan">
                <div class="flex-row d-md-block d-none">
                  <div class="navbar-row-control d-flex align-items-center">Economisim bani, nu dragoste.</div>
                </div>
              </div>

              <div class="flex-row d-md-none border-bottom d-flex justify-content-between px-2">
                <a class="navbar-brand brand-logo" href="https://www.penny.ro/" target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 396.19 400"
                    preserveAspectRatio="xMaxYMin meet">
                    <path fill-rule="evenodd" clip-rule="evenodd" fill="#cd1316"
                      d="M28.69 31h338.52v338.52H28.69z"></path>
                    <path d="M339.83 216.91a11.69 11.69 0 11-23.37 0 11.69 11.69 0 0123.37 0z" fill="#ffd400">
                    </path>
                    <path
                      d="M80.64 199.23h4.39c4.94 0 7.94-3.05 8.47-6.86.46-3.29-1.83-6.86-6.48-6.86h-4.17l-2.21 13.72zm219.74-7.93l9.89-18.56h22.75l-34.34 54.35h-23.15l11.01-15.51-12.56-37.69-8.57 53.2H247.2l-11.18-25.11-4.04 25.11h-18.21l8.75-54.35h18.68l10.83 24.32 3.92-24.32h39.79l4.64 18.56zm-85.09-18.56l-8.75 54.35h-18.21l-11.18-25.11-4.04 25.11H154.9l8.75-54.35h18.68l10.83 24.32 3.92-24.32h18.21zm-123.09-.83c12.58 0 21.11 6.8 21.19 17.78l2.73-16.95h41.23l-2.21 13.75h-21.76L132.3 193H152l-2.19 13.59h-19.77l-1.12 6.75h22.17l-2.21 13.75h-41.5l4.74-29.43c-3.19 9.53-11.89 15.15-23.27 15.15h-10.4l-2.3 14.28H56.36l8.89-55.18H92.2z"
                      fill-rule="evenodd" clip-rule="evenodd" fill="#fff"></path>
                  </svg>
                </a>
                <button class="navbar-toggler order-2" type="button" data-toggle="offcanvas"
                  data-target="#navbarMenu" aria-controls="navbarMenu" aria-expanded="false"
                  aria-label="Toggle navigation">
                  <svg id="toggler-close" xmlns="http://www.w3.org/2000/svg"
                    x="0px" y="0px" viewBox="0 0 36 36"
                    fill="currentColor"  height="20" width="20"
                    >
                    <polygon
                      points="31.8,6.3 29.7,4.2 18,15.9 6.3,4.2 4.2,6.3 15.9,18 4.2,29.7 6.3,31.8 18,20.1 29.7,31.8 31.8,29.7 20.1,18 " />
                  </svg>
                </button>
                <div>
                </div>
              </div>

              <div class="flex-row">
                <ul class="navbar-nav mr-auto">
                  <li class="nav-item dropdown">
                    <a class="nav-link" href="#" id="navbarDropdownOne" role="button" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                      Oferte
                      <span class="expand-arrow">
                        <svg class="d-none d-md-inline-block arrow-down" xmlns="http://www.w3.org/2000/svg"
                          x="0px" y="0px" viewBox="0 0 36 36"
                          fill="currentColor"  height="17"
                          width="17" >
                          <polygon
                            points="28,10.5 27.9,10.4 18,20.3 8.1,10.4 5.5,13.1 17.9,25.5 18,25.6 30.4,13.1 30.5,13.1 " />
                        </svg>

                        <svg class="d-md-none d-inline-block arrow-right" xmlns="http://www.w3.org/2000/svg"
                          x="0px" y="0px" viewBox="0 0 36 36"
                          fill="currentColor"  height="20"
                          width="20" >
                          <polygon points="20.5,18 10.6,27.9 13.1,30.4 25.4,18 13.1,5.6 10.6,8.1 " />
                        </svg>
                      </span>
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdownOne">
                      <div class="navbar-dropdown-overflow">
                        <div class="row navbar-dropdown-container">
                          <div class="col-md-3 border-right">
                            <button class="d-md-none d-block navbar-mobile-back btn btn-link ml-1">
                              <span aria-hidden="true">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                  x="0px" y="0px"
                                  viewBox="0 0 36 36" fill="currentColor"
                                   height="14" width="14"
                                  >
                                  <polygon
                                    points="10.9,18 22.9,6 25.1,8.1 15.2,18 25.1,27.9 22.9,30 " />
                                </svg>
                              </span>
                              <span class="navbar-back-action">Înapoi</span>
                            </button>
                            <h4 class="dropdown-title text-uppercase mb-3 pl-2">
                              <a href="https://www.penny.ro/oferte" target="_blank">Oferte</a>
                            </h4>
                            <a class="dropdown-item"
                              href="https://www.penny.ro/vezi-pliantele-saptamanii">Pliante</a>
                            <a class="dropdown-item"
                              href="https://www.penny.ro/oferta-saptamanii">Ofertele Săptămânii</a>
                            <a class="dropdown-item" href="https://www.penny.ro/oferte-card">Oferte cu
                              cardul PENNY</a>
                            <a class="dropdown-item"
                              href="https://www.penny.ro/cu-penny-la-masa">Cataloage</a>
                          </div>
                          <div class="col-md-3 border-right d-md-block d-none">
                            <a href="https://www.penny.ro/oferta-saptamanii" target="_blank">
                              <img src="https://fotbal.penny.ro/resources/header/thumbnails/oferte1.jpg"
                                alt=""/>
                            </a>
                            <h4 style={{color:"#cd1316"}} class="text-type-dark dropdown-title mt-1">
                              Ofertele Săptămânii!
                            </h4>
                          </div>
                          <div class="col-md-3 border-right d-md-block d-none">
                            <a href="https://www.penny.ro/oferte-card" target="_blank">
                              <img src="https://fotbal.penny.ro/resources/header/thumbnails/card.jpg" alt=""/>
                            </a>
                            <h4 style={{color:"#cd1316"}} class="text-type-dark dropdown-title mt-1">
                              Descoperă oferta de produse cu cardul PENNY!
                            </h4>
                          </div>
                          <div class="col-md-3 border-right d-md-block d-none">
                            <a href="https://www.penny.ro/colectia-vivess" target="_blank">
                              <img src="https://fotbal.penny.ro/resources/header/thumbnails/vivess.webp"
                                alt=""/>
                            </a>
                            <h4 style={{color:"#cd1316"}} class="text-type-dark dropdown-title mt-1">
                              Dă-ți imaginația la maxim, iar prețurile la minim, cu noua colecție
                              VIVESS!
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li class="nav-item dropdown">
                    <a class="nav-link" href="#" id="navbarDropdownTwo" role="button" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                      Sortiment
                      <span class="expand-arrow">
                        <svg class="d-none d-md-inline-block arrow-down" xmlns="http://www.w3.org/2000/svg"
                          x="0px" y="0px" viewBox="0 0 36 36"
                          fill="currentColor"  height="17"
                          width="17" >
                          <polygon
                            points="28,10.5 27.9,10.4 18,20.3 8.1,10.4 5.5,13.1 17.9,25.5 18,25.6 30.4,13.1 30.5,13.1 " />
                        </svg>

                        <svg class="d-md-none d-inline-block arrow-right" xmlns="http://www.w3.org/2000/svg"
                          x="0px" y="0px" viewBox="0 0 36 36"
                          fill="currentColor"  height="20"
                          width="20" >
                          <polygon points="20.5,18 10.6,27.9 13.1,30.4 25.4,18 13.1,5.6 10.6,8.1 " />
                        </svg>
                      </span>
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdownTwo">
                      <div class="navbar-dropdown-overflow">
                        <div class="row navbar-dropdown-container">
                          <div class="col-md-3 border-right">
                            <button class="d-md-none d-block navbar-mobile-back btn btn-link ml-1">
                              <span aria-hidden="true">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                  x="0px" y="0px"
                                  viewBox="0 0 36 36" fill="currentColor"
                                   height="14" width="14"
                                  >
                                  <polygon
                                    points="10.9,18 22.9,6 25.1,8.1 15.2,18 25.1,27.9 22.9,30 " />
                                </svg>
                              </span>
                              <span class="navbar-back-action">Înapoi</span>
                            </button>
                            <h4 class="dropdown-title text-uppercase mb-3 pl-2">
                              <a href="https://www.penny.ro/produse" target="_blank">Sortiment</a>
                            </h4>
                            <a class="dropdown-item" href="https://www.penny.ro/nou-la-penny">Nou la
                              PENNY!</a>
                            <a class="dropdown-item"
                              href="https://www.penny.ro/produse/carne-proaspata">Carne proaspătă și
                              preparate din carne</a>
                            <a class="dropdown-item" href="https://www.penny.ro/legume-fructe">Legume și
                              fructe</a>
                            <a class="dropdown-item" href="https://www.penny.ro/lactate">Lactate</a>
                            <a class="dropdown-item" href="https://www.penny.ro/brutarie">Pâine și
                              produse de patiserie</a>
                            <a class="dropdown-item"
                              href="https://www.penny.ro/produse/marci-proprii/penny-brands">Mărci
                              Proprii</a>
                            <a class="dropdown-item" href="https://www.penny.ro/hanul-boieresc">HANUL
                              BOIERESC</a>
                            <a class="dropdown-item" href="https://www.penny.ro/macelarii">MĂCELĂRII</a>
                            <a class="dropdown-item"
                              href="https://www.penny.ro/calitatea-siguranta-alimentului">Calitatea și
                              siguranța alimentului</a>
                          </div>
                          <div class="col-md-3 border-right d-md-block d-none">
                            <a href="https://www.penny.ro/produse" target="_blank">
                              <img src="https://fotbal.penny.ro/resources/header/thumbnails/coperta-sortiment.jpg"
                                alt=""/>
                            </a>
                            <h4 class="dropdown-title mt-1">
                              <a href="https://www.penny.ro/marci-proprii"
                                target="_blank">Sortiment</a>
                            </h4>
                          </div>
                          <div class="col-md-3 border-right d-md-block d-none">
                            <a href="https://www.penny.ro/nou-la-penny" target="_blank">
                              <img src="https://fotbal.penny.ro/resources/header/thumbnails/coperta-teaser-noutati.jpg"
                                alt=""/>
                            </a>
                            <h4 class="dropdown-title mt-1">
                              <a href="https://www.penny.ro/nou-la-penny" target="_blank">Nou la
                                PENNY!</a>
                            </h4>
                          </div>
                          <div class="col-md-3 d-md-block d-none">
                            <a href="https://www.penny.ro/produse/marci-proprii/penny-brands"
                              target="_blank">
                              <img src="https://fotbal.penny.ro/resources/header/thumbnails/coperta-teaser-marci-proprii.jpg"
                                alt=""/>
                            </a>
                            <h4 class="dropdown-title mt-1">
                              <a href="https://www.penny.ro/produse/marci-proprii/penny-brands"
                                target="_blank">Mărci Proprii</a>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li class="nav-item dropdown">
                    <a class="nav-link" href="#" id="navbarDropdownThree" role="button" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                      PENNY Card
                      <span class="expand-arrow">
                        <svg class="d-none d-md-inline-block arrow-down" xmlns="http://www.w3.org/2000/svg"
                          x="0px" y="0px" viewBox="0 0 36 36"
                          fill="currentColor"  height="17"
                          width="17" >
                          <polygon
                            points="28,10.5 27.9,10.4 18,20.3 8.1,10.4 5.5,13.1 17.9,25.5 18,25.6 30.4,13.1 30.5,13.1 " />
                        </svg>

                        <svg class="d-md-none d-inline-block arrow-right" xmlns="http://www.w3.org/2000/svg"
                          x="0px" y="0px" viewBox="0 0 36 36"
                          fill="currentColor"  height="20"
                          width="20" >
                          <polygon points="20.5,18 10.6,27.9 13.1,30.4 25.4,18 13.1,5.6 10.6,8.1 " />
                        </svg>
                      </span>
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdownThree">
                      <div class="navbar-dropdown-overflow">
                        <div class="row navbar-dropdown-container">
                          <div class="col-md-3 border-right">
                            <button class="d-md-none d-block navbar-mobile-back btn btn-link ml-1">
                              <span aria-hidden="true">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                  x="0px" y="0px"
                                  viewBox="0 0 36 36" fill="currentColor"
                                   height="14" width="14"
                                  >
                                  <polygon
                                    points="10.9,18 22.9,6 25.1,8.1 15.2,18 25.1,27.9 22.9,30 " />
                                </svg>
                              </span>
                              <span class="navbar-back-action">Înapoi</span>
                            </button>
                            <h4 class="dropdown-title text-uppercase mb-3 pl-2">
                              <a href="https://www.penny.ro/cum-obtii-penny-card-penny"
                                target="_blank">PENNY Card</a>
                            </h4>
                            <a class="dropdown-item"
                              href="https://www.penny.ro/campanii-penny-card-penny">Campanii PENNY
                              Card</a>
                            <a class="dropdown-item"
                              href="https://www.penny.ro/cum-obtii-penny-card-penny">Cum obtii PENNY
                              Card?</a>
                            <a class="dropdown-item"
                              href="https://www.penny.ro/profil?tab=Card%2520digital">Card Digital</a>
                            <a class="dropdown-item"
                              href="https://www.penny.ro/campanii-de-loializare-penny">Campanii de
                              loializare</a>

                          </div>
                          <div class="col-md-3 border-right d-md-block d-none">
                            <a href="https://www.penny.ro/campanii-penny-card-penny" target="_blank">
                              <img src="https://fotbal.penny.ro/resources/header/thumbnails/coperta-campanii-concursuri-card.jpg"
                                alt=""/>
                            </a>
                            <h4 class="dropdown-title mt-1">
                              <a href="https://www.penny.ro/campanii-penny-card-penny"
                                target="_blank">Campanii PENNY Card</a>
                            </h4>
                          </div>
                          <div class="col-md-3 border-right d-md-block d-none">
                            <a href="https://www.penny.ro/cum-obtii-penny-card-penny" target="_blank">
                              <img src="https://fotbal.penny.ro/resources/header/thumbnails/coperta-cum-obtii-card-penny.jpg"
                                alt=""/>
                            </a>
                            <h4 class="dropdown-title mt-1">
                              <a href="https://www.penny.ro/cum-obtii-penny-card-penny"
                                target="_blank">Cum obtii PENNY Card?</a>
                            </h4>
                          </div>
                          <div class="col-md-3 d-md-block d-none">
                            <a href="https://www.penny.ro/oferte-card" target="_blank">
                              <img src="https://fotbal.penny.ro/resources/header/thumbnails/card.webp"
                                alt=""/>
                            </a>
                            <h4 class="dropdown-title mt-1">
                              <a href="https://www.penny.ro/oferte-card" target="_blank">Cardul
                                PENNY îți aduce noi oferte săptămânal!</a>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li class="nav-item dropdown">
                    <a class="nav-link" href="#" id="navbarDropdownFour" role="button" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                      Noutăți
                      <span class="expand-arrow">
                        <svg class="d-none d-md-inline-block arrow-down" xmlns="http://www.w3.org/2000/svg"
                          x="0px" y="0px" viewBox="0 0 36 36"
                          fill="currentColor"  height="17"
                          width="17" >
                          <polygon
                            points="28,10.5 27.9,10.4 18,20.3 8.1,10.4 5.5,13.1 17.9,25.5 18,25.6 30.4,13.1 30.5,13.1 " />
                        </svg>

                        <svg class="d-md-none d-inline-block arrow-right" xmlns="http://www.w3.org/2000/svg"
                          x="0px" y="0px" viewBox="0 0 36 36"
                          fill="currentColor"  height="20"
                          width="20" >
                          <polygon points="20.5,18 10.6,27.9 13.1,30.4 25.4,18 13.1,5.6 10.6,8.1 " />
                        </svg>
                      </span>
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdownFour">
                      <div class="navbar-dropdown-overflow">
                        <div class="row navbar-dropdown-container">
                          <div class="col-md-3 border-right">
                            <button class="d-md-none d-block navbar-mobile-back btn btn-link ml-1">
                              <span aria-hidden="true">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                  x="0px" y="0px"
                                  viewBox="0 0 36 36" fill="currentColor"
                                   height="14" width="14"
                                  >
                                  <polygon
                                    points="10.9,18 22.9,6 25.1,8.1 15.2,18 25.1,27.9 22.9,30 " />
                                </svg>
                              </span>
                              <span class="navbar-back-action">Înapoi</span>
                            </button>
                            <h4 class="dropdown-title text-uppercase mb-3 pl-2">
                              <a href="https://www.penny.ro/campanii" target="_blank">Noutăți</a>
                            </h4>
                            <a class="dropdown-item" href="https://www.penny.ro/triplu-ro">TripluRO</a>
                            <a class="dropdown-item" href="https://www.penny.ro/campanii">Campanii</a>
                            <a class="dropdown-item" href="https://www.penny.ro/retete-penny">Rețete</a>
                            <a class="dropdown-item"
                              href="https://www.penny.ro/penny-inspiratie/articole">Articole</a>
                            <a class="dropdown-item" href="https://www.penny.ro/campanii/penny-fm">PENNY
                              FM</a>
                          </div>
                          <div class="col-md-3 border-right d-md-block d-none">
                            <a href="https://www.penny.ro/campanii" target="_blank">
                              <img src="https://fotbal.penny.ro/resources/header/thumbnails/coperta-campanii.jpg"
                                alt=""/>
                            </a>
                            <h4 class="dropdown-title mt-1">
                              <a href="https://www.penny.ro/campanii" target="_blank">Campanii</a>
                            </h4>
                          </div>
                          <div class="col-md-3 border-right d-md-block d-none">
                            <a href="https://www.penny.ro/retete-penny" target="_blank">
                              <img src="https://fotbal.penny.ro/resources/header/thumbnails/coperta-retete.jpg"
                                alt=""/>
                            </a>
                            <h4 class="dropdown-title mt-1">
                              <a href="https://www.penny.ro/retete-penny" target="_blank">Rețete</a>
                            </h4>
                          </div>
                          <div class="col-md-3 d-md-block d-none">
                            <a href="https://www.penny.ro/campanii/penny-fm" target="_blank">
                              <img src="https://fotbal.penny.ro/resources/header/thumbnails/coperta-penny-fm.jpg"
                                alt=""/>
                            </a>
                            <h4 class="dropdown-title mt-1">
                              <a href="https://www.penny.ro/campanii/penny-fm" target="_blank">PENNY
                                FM</a>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li class="nav-item dropdown">
                    <a class="nav-link text-brand" href="#" id="navbarDropdownFive" role="button"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Fotbalul Unește
                      <span class="expand-arrow">
                        <svg class="d-none d-md-inline-block arrow-down" xmlns="http://www.w3.org/2000/svg"
                          x="0px" y="0px" viewBox="0 0 36 36"
                          fill="currentColor"  height="17"
                          width="17" >
                          <polygon
                            points="28,10.5 27.9,10.4 18,20.3 8.1,10.4 5.5,13.1 17.9,25.5 18,25.6 30.4,13.1 30.5,13.1 " />
                        </svg>

                        <svg class="d-md-none d-inline-block arrow-right" xmlns="http://www.w3.org/2000/svg"
                          x="0px" y="0px" viewBox="0 0 36 36"
                          fill="currentColor"  height="20"
                          width="20" >
                          <polygon points="20.5,18 10.6,27.9 13.1,30.4 25.4,18 13.1,5.6 10.6,8.1 " />
                        </svg>
                      </span>
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdownFive">
                      <div class="navbar-dropdown-overflow">
                        <div class="row navbar-dropdown-container">
                          <div class="col-md-3 border-right">
                            <button class="d-md-none d-block navbar-mobile-back btn btn-link ml-1">
                              <span aria-hidden="true">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                  x="0px" y="0px"
                                  viewBox="0 0 36 36" fill="currentColor"
                                   height="14" width="14"
                                  >
                                  <polygon
                                    points="10.9,18 22.9,6 25.1,8.1 15.2,18 25.1,27.9 22.9,30 " />
                                </svg>
                              </span>
                              <span class="navbar-back-action">Înapoi</span>
                            </button>
                            <h4 class="dropdown-title text-uppercase mb-3 pl-2">
                              <a href="https://fotbal.penny.ro/" target="_blank">Fotbalul Unește</a>
                            </h4>
                            <a class="dropdown-item"
                              href="https://fotbal.penny.ro/fotbalul-uneste">FOTBALUL UNEȘTE</a>
                            <a class="dropdown-item"
                              href="https://fotbal.penny.ro/suportero">SUPORTERO</a>

                          </div>
                          <div class="col-md-3 border-right d-md-block d-none">
                            <a href="https://fotbal.penny.ro/suportero" target="_blank">
                              <img src="https://fotbal.penny.ro/resources/header/thumbnails/uneste.webp"
                                alt=""/>
                            </a>
                            <h4 class="dropdown-title mt-1">
                              <a href="https://fotbal.penny.ro" target="_blank">Fotbalul Unește</a>
                            </h4>
                          </div>
                          <div class="col-md-3 border-right d-md-block d-none">
                            <a href="https://fotbal.penny.ro/suportero" target="_blank">
                              <img src="https://fotbal.penny.ro/resources/header/thumbnails/suportero.jpg"
                                alt=""/>
                            </a>
                            <h4 class="dropdown-title mt-1">
                              <a href="https://fotbal.penny.ro/suportero" target="_blank">Energie cât
                                o galerie!</a>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li class="nav-item dropdown">
                    <a class="nav-link" href="#" id="navbarDropdownSix" role="button" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                      Memoria Gustului
                      <span class="expand-arrow">
                        <svg class="d-none d-md-inline-block arrow-down" xmlns="http://www.w3.org/2000/svg"
                          x="0px" y="0px" viewBox="0 0 36 36"
                          fill="currentColor"  height="17"
                          width="17" >
                          <polygon
                            points="28,10.5 27.9,10.4 18,20.3 8.1,10.4 5.5,13.1 17.9,25.5 18,25.6 30.4,13.1 30.5,13.1 " />
                        </svg>

                        <svg class="d-md-none d-inline-block arrow-right" xmlns="http://www.w3.org/2000/svg"
                          x="0px" y="0px" viewBox="0 0 36 36"
                          fill="currentColor"  height="20"
                          width="20" >
                          <polygon points="20.5,18 10.6,27.9 13.1,30.4 25.4,18 13.1,5.6 10.6,8.1 " />
                        </svg>
                      </span>
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdownSix">
                      <div class="navbar-dropdown-overflow">
                        <div class="row navbar-dropdown-container">
                          <div class="col-md-3 border-right">
                            <button class="d-md-none d-block navbar-mobile-back btn btn-link ml-1">
                              <span aria-hidden="true">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                  x="0px" y="0px"
                                  viewBox="0 0 36 36" fill="currentColor"
                                   height="14" width="14"
                                  >
                                  <polygon
                                    points="10.9,18 22.9,6 25.1,8.1 15.2,18 25.1,27.9 22.9,30 " />
                                </svg>
                              </span>
                              <span class="navbar-back-action">Înapoi</span>
                            </button>
                            <h4 class="dropdown-title text-uppercase mb-3 pl-2">
                              <a href="https://www.memoriagustului.ro/" target="_blank">Memoria
                                Gustului</a>
                            </h4>
                            <a style={{textTransform:"uppercase"}} class="dropdown-item" href="https://www.penny.ro/hanul-boieresc">Memoria
                              Gustului</a>
                            <a style={{textTransform:"uppercase"}} class="dropdown-item"
                              href="https://www.penny.ro/hanul-boieresc/produse">Produse Hanul Boieresc</a>
                            <a style={{textTransform:"uppercase"}} class="dropdown-item"
                              href="https://www.penny.ro/hanul-boieresc/retete">Rețete</a>

                          </div>
                          <div class="col-md-3 border-right d-md-block d-none">
                            <a href="https://www.penny.ro/hanul-boieresc/editii-de-sarbatoare/memoria-gustului-de-vara-2024" target="_blank">
                              <img src="https://fotbal.penny.ro/resources/header/thumbnails/mem.webp"
                                alt=""/>
                            </a>
                            <h4 class="dropdown-title mt-1">
                              <a href="https://www.penny.ro/hanul-boieresc/editii-de-sarbatoare/memoria-gustului-de-vara-2024" target="_blank">Memoria
                                gustului de vară Chef Alex Petricean</a>
                            </h4>
                          </div>
                          <div class="col-md-3 border-right d-md-block d-none">
                            <a href="https://www.penny.ro/hanul-boieresc/produse" target="_blank">
                              <img src="https://fotbal.penny.ro/resources/header/thumbnails/hb.webp"
                                alt=""/>
                            </a>
                            <h4 class="dropdown-title mt-1">
                              <a href="https://www.penny.ro/hanul-boieresc/produse" target="_blank">Produse
                                Hanul Boieresc</a>
                            </h4>
                          </div>
                          <div class="col-md-3 border-right d-md-block d-none">
                            <a href="https://www.penny.ro/hanul-boieresc/retete" target="_blank">
                              <img src="https://fotbal.penny.ro/resources/header/thumbnails/coperta-retete-hb.jpg"
                                alt=""/>
                            </a>
                            <h4 class="dropdown-title mt-1">
                              <a href="https://www.penny.ro/hanul-boieresc/retete"
                                target="_blank">Rețete</a>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>


                  <li class="nav-item">
                    <a class="nav-link" href="https://sustenabilitate.penny.ro/" target="_blank">SUSTENABILITATE</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="https://cariere.penny.ro/" target="_blank">Cariere</a>
                  </li>
                </ul>
              </div>

              <div class="flex-row">
                <div class="d-md-block d-none">
                  <form id="navbar-search-form" class="form-inline my-2 my-lg-0 ml-2 navbar-row-control">
                    <input id="navbar-search-input" class="form-control mr-sm-2 w-100" type="search"
                      placeholder="Căutați conținut, categorii sau produse"/>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-between order-md-2 order-1">
            <div class="quick-links mb-md-2 p-md-1">
              <a href="https://www.penny.ro/search/fotbal" class="d-md-none">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                  viewBox="0 0 36 36" fill="currentColor"  height="28"
                  width="28" >
                  <path
                    d="M36,33.9L22.9,20.7c1.7-2.2,2.8-4.9,2.8-7.9C25.6,5.8,19.9,0,12.8,0C5.8,0,0,5.8,0,12.8c0,7.1,5.7,12.8,12.8,12.8
                    c3,0,5.7-1,7.9-2.8L33.9,36L36,33.9z M3,12.8C3,7.4,7.4,3,12.8,3c5.4,0,9.8,4.4,9.8,9.8s-4.4,9.8-9.8,9.8C7.4,22.6,3,18.2,3,12.8z" />
                </svg>
              </a>
              <a class="navbar-action-item" href="https://www.penny.ro/magazinul-meu" target="_blank"><img
                src="https://fotbal.penny.ro/resources/header/storefinder.svg" alt="" width="36" height="36"/></a>
              <a class="navbar-action-item" href="https://www.penny.ro/inregistrare" target="_blank"><img
                src="https://fotbal.penny.ro/resources/header/account.svg" alt="" width="36" height="36"/></a>
              <a class="navbar-action-item" href="https://www.penny.ro/newsletter" target="_blank"><img
                src="https://fotbal.penny.ro/resources/header/newsletter.svg" alt="" width="36" height="36"/></a>
            </div>

            <button class="navbar-toggler order-2" type="button" data-toggle="offcanvas" data-target="#navbarMenu"
              aria-controls="navbarMenu" aria-expanded="false" aria-label="Toggle navigation">
              <svg id="toggler-hamburger" xmlns="http://www.w3.org/2000/svg"
                x="0px" y="0px" viewBox="0 0 36 36" fill="currentColor"
                 height="28" width="28" >
                <path d="M36,9H0V6h36V9z M36,16.5H0v3h36V16.5z M36,27H0v3h36V27z" />
              </svg>
            </button>
          </div>
        </div>
      </nav>
    )
  }
}
export default Header

/* eslint-disable react/prefer-stateless-function */
import React from "react"
import Helmet from "react-helmet"

import Header from "./header"
import Footer from "./footer"

class Layout extends React.Component {
  render() {
    const page = this.props.pageInfo.pageName

    return (
      <div className="body akko-medium">
        <Helmet>
          <script
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            type="text/javascript"
            charset="UTF-8"
            data-domain-script="cfa6eabd-d586-464c-8c29-cfc51db86905"
          />
          <script type="text/javascript">function OptanonWrapper() {}</script>

          <script src="https://assets.adobedtm.com/8665cc68f8ef/bb19ecd7d5ba/launch-8a89dae6e6a9.min.js" async></script>
        </Helmet>
        <Header page={page} />
        <main>{this.props.children}</main>
        <Footer />
      </div>
    )
  }
}

export default Layout
